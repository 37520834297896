@page {
    size: A4;
    margin: '0px !important';
}


@media print {
   
    .print-preview{
        padding: '0px 10px 0px';
        background: white;

    }
   
}